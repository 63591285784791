var resizeTimer; 
var mobile_trigger_width_xs = 768; 
var mobile_trigger_width_sm = 980; 

$(function () {
	//Functions
	innitNav();
    initCarousel();
    initGallery();
    initMatchHeight();
    initPartners();
    initGoogleMaps();
    initInspiratie();
    // Resize
	$(window).resize(function () {
	    clearTimeout($.data(this, 'resizeTimer'));
	    $.data(this, 'resizeTimer', setTimeout(function () {
	        innitNav();    
            initMatchHeight();
	    }, 100));
	});
 

});

//
// NAVIGATION
//
var innitNav = function(){

    // SCOLL TO DIV
    $('a[href^="#"]').on('click',function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top
        }, 500);
        return false;
    });

    // SHOW MOBILE OR DEKSTOP PMENU
    if($( window ).width() < mobile_trigger_width_sm){
        $('#menu').slicknav({
            label: '',
            appendTo: '#mobilenav',
            easingOpen: "swing",
            easingClose: "swing",
            closedSymbol: '<i class="fa fa-chevron-right" aria-hidden="true"></i>',
            openedSymbol:'<i class="fa fa-chevron-down" aria-hidden="true"></i>'
        });
        $('nav').hide();
        $('.slicknav_menu').show(); 
    }else{
        $('nav').show();
        $('.slicknav_menu').hide(); 
    } 
}  

//
// CAROUSEL
//
var initCarousel = function() {
    if ($('.carousel').length > 0) {
        $('.carousel').slick({
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed:4000,
        });
        $('.carousel button.slick-prev').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
        $('.carousel button.slick-next').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
    }
} 

//
// PARTNERS
//
var initPartners = function() {
    if ($('.partners').length > 0) {
        $('.carousel-partners').slick({
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed:4000, 
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        });
    }
} 


//
// GALLERY
//
var initGallery = function() {
    if ($('.galleries').length > 0) {
        $('.galleries').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Afbeelding laden #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1]
            },
            image: {
                tError: '<a href="%url%">De afbeelding #%curr%</a> kan niet geladen worden.',
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
            }
        });
    } 
}

//
// GOOGLEMAPS
//

function initGoogleMaps(){
    if( $('#map').length > 0 && $('#map').length > 0 ){
        var marker_lat = $('#map').data('lat');
        var marker_lng = $('#map').data('lng');
        var marker_latlng = {lat: marker_lat, lng: marker_lng};
        var marker_title  = $('#map').data('data-title');
        // var marker_icon= {
        //     url: '/img/icon-map.png', 
        //     scaledSize : new google.maps.Size(100*.5,122*.5), 
        //     origin: new google.maps.Point(0, 0), 
        //     anchor: new google.maps.Point(0, (122*.5)*.5)
        //  }

         var map = new google.maps.Map(document.getElementById('map'), {
          zoom: 8,
          center: marker_latlng,
          disableDefaultUI: true
        });

        var marker = new google.maps.Marker({
          position: marker_latlng,
          map: map,
          title: marker_title,
          //icon: marker_icon
        });
    }
}

//
// MATCHHEIGHT
//
var initMatchHeight = function(){
    if($( window ).width() > mobile_trigger_width_sm){
        $('.matchheight').matchHeight({
            byRow: true,
            property: 'height',
            target: null,
            remove: false
        });
    }else{
        $('.matchheight').css('height','auto'); 
    } 
}

function initInspiratie() {
    $('.inspiratie_gallery').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            gallery: {
                enabled:true
            }
        });
    });
} 